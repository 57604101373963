import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducers from "./store/reducers/index";
import initialState from "./store/initialState.js";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import translations_sv from "./translations/sv.json";
import translations_en from "./translations/en.json";
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // language to use
  resources: {
    en: {
      translations: translations_en, // 'translations' is our custom namespace
    },
    sv: {
      translations: translations_sv,
    },
  },
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [];

// Add router middleware
// eslint-disable-next-line new-cap
middlewares.push(thunk);
const store = createStore(
  rootReducers,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>
);

serviceWorker.unregister();
