import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useIsMobile from "../../../hooks/useWindowSize";
import { Colors } from "../../Constants/Constants";
import styled from "styled-components";

const CarouselSlider = ({ images }) => {
  const isMobile = useIsMobile();

  const ImageContainer = styled.div`
    background-position: 95% 10%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 35rem;
    @media (max-width: 768px) and (min-width: 300px) {
      height: 15rem;
    }
  `;
  const Img = styled.img`
    background-position: 95% 10%;
    object-fit: cover;
  `;
  return (
    <Carousel
      autoPlay
      dynamicHeight={false}
      showStatus={false}
      infiniteLoop
      showThumbs={false}
    >
      {images.map((image) => (
        <ImageContainer>
          <Img src={image} />
        </ImageContainer>
      ))}
    </Carousel>
  );
};

export default CarouselSlider;
