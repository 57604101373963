import React from "react";
import { slide as Menu } from "react-burger-menu";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Colors, Sizes } from "../../Constants/Constants";
import Translation from "./Translation";
import styled from "styled-components";
import "./styles.css";

const MenuText = styled.span`
  font-size: ${Sizes.XSmall};
  margin: ${Sizes.Small} ${Sizes.XXSmall};
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  
  }
`;

const BurgerMenu = (props) => {
  const { t } = useTranslation("translations");
  const isMobile = window.innerWidth < 700;

  return (
    <>
      {isMobile && (
        <Menu {...props}>
          <NavLink
            to="/svenska-mattor"
            style={({ isActive }) =>
              isActive
                ? { color: `${Colors.LightBlue}` }
                : { color: `${Colors.Black}` }
            }
          >
            <MenuText>{t("Header.swedishCarpets")}</MenuText>
          </NavLink>
          <NavLink
            to="/persiska-mattor"
            style={({ isActive }) =>
              isActive
                ? { color: `${Colors.LightBlue}` }
                : { color: `${Colors.Black}` }
            }
          >
            <MenuText>{t("Header.persianCarpets")}</MenuText>
          </NavLink>
          <NavLink
            to="/moderna-mattor"
            style={({ isActive }) =>
              isActive
                ? { color: `${Colors.LightBlue}` }
                : { color: `${Colors.Black}` }
            }
          >
            <MenuText>{t("Header.modernCarpets")}</MenuText>
          </NavLink>
          <NavLink
            to="/om-oss"
            style={({ isActive }) =>
              isActive
                ? { color: `${Colors.LightBlue}` }
                : { color: `${Colors.Black}` }
            }
          >
            <MenuText>{t("Header.aboutUs")}</MenuText>
          </NavLink>
          <NavLink
            to="/kontakta-oss"
            style={({ isActive }) =>
              isActive
                ? { color: `${Colors.LightBlue}` }
                : { color: `${Colors.Black}` }
            }
          >
            <MenuText>{t("Header.contactUs")}</MenuText>
          </NavLink>
          <Translation />
        </Menu>
      )}
    </>
  );
};
export default BurgerMenu;
