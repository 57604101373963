import styled from "styled-components";

import { Colors, Sizes } from "../../Constants/Constants";

const StyledInputField = styled.input`
  background-color: ${Colors.White};
  border: 1px solid ${Colors.Gray};
  font-weight: 600;
  margin: ${Sizes.XSmall} 0;
  width: 100%;
  height: ${Sizes.Medium};
  cursor: pointer;
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.XXSmall} 0;
  }
`;

const InputField = (props) => {
  return (
    <StyledInputField
      placeholder={props.placeholder}
      onChange={props.onChange}
      name={props.name}
    />
  );
};

export default InputField;
