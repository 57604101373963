import * as actionType from "./actionTypes";

//Thunks

export const updateLanguage = (status) => {
  return {
    type: actionType.UPDATE_LANG,
    payload: status,
  };
};
