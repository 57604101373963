import PageHeader from "../../components/shared/PageHeader/PageHeader";
import { Wrapper } from "../../components/shared/BaseStyledComponents";
import Carousel from "../../components/shared/Carousel/Carousel";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Sizes } from "../../components/Constants/Constants";
import CarpetCard from "./CarpetCard";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/shared/Footer/Footer";

const UnderCarouselText = styled.h2`
  font-size: ${Sizes.Small};
  margin: ${Sizes.Small};
  font-weight: 500;
  width: 50%;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: ${Sizes.XSmall};
    letter-spacing: 1px;
    width: 90%;
  }
`;

const images = [
  "https://firebasestorage.googleapis.com/v0/b/mattgallery-71669.appspot.com/o/others%2Fheader8.png?alt=media&token=7b3705cd-3d2b-4c8b-8831-39f1aec30793",

  "https://firebasestorage.googleapis.com/v0/b/mattgallery-71669.appspot.com/o/SwedishCarpets%2Fnum7%2Fnum7-2.jpg?alt=media&token=4dbe1501-2054-43e3-94ba-9569b18de9d3",
  "https://firebasestorage.googleapis.com/v0/b/mattgallery-71669.appspot.com/o/others%2Fheader4.jpeg?alt=media&token=5e384f7c-b784-455b-8338-b7b1ec468c27",
];

const Home = () => {
  const { t } = useTranslation("translations");
  const navigate = useNavigate();
  return (
    <Wrapper>
      <PageHeader />
      <Carousel images={images} />
      <UnderCarouselText>{t("Home.text1")}</UnderCarouselText>
      <CarpetCard
        title={t("Home.svenskaAntikaMattor")}
        onButtonClick={() => navigate("/svenska-mattor")}
        buttonTitle={t("Home.ourCarpets")}
        imageSrc={
          "https://firebasestorage.googleapis.com/v0/b/mattgallery-71669.appspot.com/o/SwedishCarpets%2Fnum20%2Fnum20-2.jpg?alt=media&token=3d463e75-c9fb-4a0f-b3f2-1e6e6d32fdc5"
        }
      >
        {t("Home.text2")}
      </CarpetCard>
      <CarpetCard
        reverse
        onButtonClick={() => navigate("/persiska-mattor")}
        buttonTitle={t("Home.ourCarpets")}
        title={t("Home.persianAntikaMattor")}
        imageSrc={
          "https://firebasestorage.googleapis.com/v0/b/mattgallery-71669.appspot.com/o/others%2Ffirstpage-persiska.jpg?alt=media&token=a00762d2-e18a-4003-a8e7-f2bed75169ae"
        }
      >
        {t("Home.persiskaMattor")}
      </CarpetCard>
      <CarpetCard
        title={t("Home.modernaDesignaMattor")}
        buttonTitle={t("Home.comingSoon")}
        onButtonClick={() => navigate("/moderna-mattor")}
        imageSrc={
          "https://firebasestorage.googleapis.com/v0/b/mattgallery-71669.appspot.com/o/others%2Ffirstpage-moderna.jpg?alt=media&token=9975cfe8-85bd-4f25-a6c3-a00be7e696c8"
        }
      >
        {t("Home.modernaCarpet")}
      </CarpetCard>
      <Footer></Footer>
    </Wrapper>
  );
};

export default Home;
