// import React from "react";

import PageHeader from "../../components/shared/PageHeader/PageHeader";
import { Wrapper } from "../../components/shared/BaseStyledComponents";
import styled from "styled-components";
import { Sizes, Colors } from "../../components/Constants/Constants";
import { useTranslation } from "react-i18next";
import Footer from "../../components/shared/Footer/Footer";

const Image = styled.img`
  width: 100%;
  height: ${Sizes.XXXXLarge};
  background-color: ${Colors.White};
  object-fit: cover;
  @media (max-width: 768px) and (min-width: 300px) {
    height: ${Sizes.XXXLarge};
  }
`;

const Title = styled.span`
  font-size: ${Sizes.Large};
  text-transform: uppercase;
  margin: ${Sizes.Medium};
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: ${Sizes.Small};
  }
`;
const Text = styled.p`
  font-size: ${Sizes.Small};
  margin: ${Sizes.Small};
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: ${Sizes.XSmall};
  }
`;
const Contactus = () => {
  const { t } = useTranslation("translations");

  return (
    <Wrapper>
      <PageHeader />
      <Image src="https://firebasestorage.googleapis.com/v0/b/mattgallery-71669.appspot.com/o/others%2Fheader2.jpg?alt=media&token=c3bf01ac-a962-4b14-806a-1e3634f652cd"></Image>
      <Title>{t("ContactUs.contactUs")}</Title>
      <Text>{t("ContactUs.text1")} </Text>

      <Text>Adress: Brännarevägen 9 - 15155 - Södertälje</Text>
      <Text>Email: Info@mattgallery.se</Text>

      <Text>Tel: +46-8219016 - +46-732323991 </Text>
      <Footer></Footer>
    </Wrapper>
  );
};

export default Contactus;
