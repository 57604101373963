import * as actionType from "../actions/actionTypes";
import initialState from "../initialState";
const apiStatus = (state = initialState.apiStatus, action) => {
  switch (action.type) {
    case actionType.API_SEND_ASK_FOR_PRICE_EMAIL:
      return {
        ...state,
        sendEmailAskForPrice: action.payload,
      };
    default:
      return state;
  }
};
export default apiStatus;
