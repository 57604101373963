import { Link } from "react-router-dom";
import styled from "styled-components";
import allCarpets from "../../../database/allCarpets.json";
import { useTranslation } from "react-i18next";
import { Sizes } from "../../Constants/Constants";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 1rem;

  @media (max-width: 768px) and (min-width: 300px) {
    justify-content: center;
  }
`;

const SoldLabel = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background-color: #ededed;
  left: ${Sizes.Small};
  top: ${Sizes.XXSmall};
  box-shadow: 0px 0px 9px 0px rgba(150, 150, 150, 0.9);
  padding: ${Sizes.XXSmall};
  z-index: 999999;
  @media (max-width: 768px) and (min-width: 300px) {
    left: -${Sizes.XXSmall};
    top: ${Sizes.XXSmall};
  }
`;

const SoldText = styled.span`
  font-size: ${Sizes.XSmall};
`;

const CarpetImage = styled.div`
  margin: 2rem 1rem;
  width: 25rem;
  height: 30rem;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  background-image: url(${(props) => props.imgSrc});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto 100%;
  transition: 0.2s ease-in-out;
  &:hover {
    scale: 1.04;
  }
  @media (max-width: 768px) and (min-width: 300px) {
    margin: 2rem 0rem;
    width: 20rem;
    height: 30rem;
  }
`;

const Carpets = (props) => {
  const { t } = useTranslation("translations");
  return (
    <Container>
      {allCarpets &&
        allCarpets[props.type].map((carpet, index) => (
          <Link
            style={{ position: "relative" }}
            to={`/${props.type}/${carpet.number}`}
            key={index}
          >
            {carpet.sold && (
              <SoldLabel>
                <SoldText>{t("CarpetPage.sold")}</SoldText>
              </SoldLabel>
            )}
            <CarpetImage imgSrc={carpet.imgs[0]}></CarpetImage>
          </Link>
        ))}
    </Container>
  );
};

export default Carpets;
