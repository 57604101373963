import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Colors, Sizes } from "../../Constants/Constants";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Sizes.Medium};
  padding: ${Sizes.XSmall};
  background-color: ${Colors.Black};
  width: 100%;
  @media (max-width: 768px) and (min-width: 300px) {
    padding: ${Sizes.XSmall} 0;
  }
`;
const HeaderText = styled.span`
  font-size: ${Sizes.Large};
  color: ${Colors.White};
  text-transform: uppercase;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: ${Sizes.Small};
  }
`;

const MenuText = styled.span`
  font-size: ${Sizes.XSmall};
  margin: ${Sizes.XXSmall};
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  display: inline-block;
  color: ${Colors.White};

  &:hover::after {
    width: 100%;
  }
  &:after {
    content: "";
    width: 0px;
    height: 1px;
    display: block;
    background: ${Colors.LightBlue};
    transition: 300ms;
  }
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: ${Sizes.XXSmall};
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: ${Sizes.Medium} 0;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column;
  }
`;

const Footer = () => {
  const { t } = useTranslation("translations");
  return (
    <Container>
      <HeaderText>mattgallery</HeaderText>
      <MenuContainer>
        <NavLink to="/svenska-mattor">
          <MenuText>{t("Header.swedishCarpets")}</MenuText>
        </NavLink>
        <NavLink to="/persiska-mattor">
          <MenuText>{t("Header.persianCarpets")}</MenuText>
        </NavLink>
        <NavLink to="/moderna-mattor">
          <MenuText>{t("Header.modernCarpets")}</MenuText>
        </NavLink>
        <NavLink to="/om-oss">
          <MenuText>{t("Header.aboutUs")}</MenuText>
        </NavLink>
        <NavLink to="/kontakta-oss">
          <MenuText>{t("Header.contactUs")}</MenuText>
        </NavLink>
      </MenuContainer>
    </Container>
  );
};

export default Footer;
