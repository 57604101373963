export const ApiStatus = {
  Idle: 10,
  Started: 11,
  Completed: 12,
  Failed: 13,
};

export const Colors = {
  White: "#fff",
  Black: "#444444",
  Gray: "#a3a3a3",
  LightBlue: "#0080ff",
  DarkBlue: "#004d99",
  Warning: "#fc1c1c",
};

export const Sizes = {
  XXXSmall: "0.5rem",
  XXSmall: "0.75rem",
  XSmall: "1rem",
  Small: "1.5rem",
  Medium: "2rem",
  Large: "3rem",
  XLarge: "5rem",
  XXLarge: "8rem",
  XXXLarge: "13rem",
  XXXXLarge: "30rem",
};
