import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import SwedishCarpets from "./pages/SwedishCarpets/SwedishCarpets";
import PersianCarpets from "./pages/PersianCarpets/PersianCarpets";
import ModernCarpets from "./pages/ModernCarpets/ModernCarpets";
import Aboutus from "./pages/Aboutus/Aboutus";
import Contactus from "./pages/Contactus/Contactus";
import Carpet from "./pages/Carpet/Carpet";
import ScrollToTop from "./components/helpers/scrollToTop";

const Navigation = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/svenska-mattor" element={<SwedishCarpets />}></Route>
        <Route path="/persiska-mattor" element={<PersianCarpets />}></Route>
        <Route path="/moderna-mattor" element={<ModernCarpets />}></Route>
        <Route path="/om-oss" element={<Aboutus />}></Route>
        <Route path="/kontakta-oss" element={<Contactus />}></Route>
        <Route path="/svenska-mattor/:carpetID" element={<Carpet />}></Route>
        <Route path="/persiska-mattor/:carpetID" element={<Carpet />}></Route>

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>Page Not Found!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
