import PageHeader from "../../components/shared/PageHeader/PageHeader";
import { Wrapper } from "../../components/shared/BaseStyledComponents";
import Footer from "../../components/shared/Footer/Footer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Colors, Sizes } from "../../components/Constants/Constants";

const UnderPictureHeader = styled.span`
  font-size: ${Sizes.Large};
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: ${Sizes.Small};
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.Small};
    font-size: ${Sizes.Small};
  }
`;

const UnderPictureText = styled.span`
  font-size: ${Sizes.Small};
  margin: ${Sizes.Small} ${Sizes.XXXLarge};
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.Small};
    font-size: ${Sizes.XSmall};
  }
`;

const Image = styled.img`
  width: 100%;
  height: ${Sizes.XXXXLarge};
  background-color: ${Colors.White};
  object-fit: cover;
  @media (max-width: 768px) and (min-width: 300px) {
    height: ${Sizes.XXXLarge};
  }
`;

const ComingSoon = styled.span`
  font-size: 30px;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 18px;
  }
`;

const ModernaCarpets = () => {
  const { t } = useTranslation("translations");
  return (
    <Wrapper>
      <PageHeader />
      <Image src="https://firebasestorage.googleapis.com/v0/b/mattgallery-71669.appspot.com/o/PersianCarpets%2Fnum1%2FMatta1-1.jpg?alt=media&token=05a46a33-1fb2-494d-b9ca-ea74bd02b0e8"></Image>
      <UnderPictureHeader>
        {t("ModernCarpets.modernaDesignaMattor")}
      </UnderPictureHeader>
      <UnderPictureText>{t("ModernCarpets.modernaCarpet")}</UnderPictureText>
      {/* <Carpets type="persiska-mattor" /> */}
      <ComingSoon>COMING SOON!</ComingSoon>
      <Footer />
    </Wrapper>
  );
};

export default ModernaCarpets;
