import { ApiStatus } from "../components/Constants/Constants";

const initialState = {
  apiStatus: {
    sendEmailAskForPrice: ApiStatus.Idle,
  },
  language: "en",
};

export default initialState;
