import * as actionType from "../actions/actionTypes";
import initialState from "../initialState";
const languageStatus = (state = initialState.language, action) => {
  switch (action.type) {
    case actionType.UPDATE_LANG:
      
      return action.payload;
    default:
      
      return state;
  }
};
export default languageStatus;
