import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { updateLanguage } from "../../../store/actions/language";

// SVG or Image URLs for the flags
import SwedenFlag from "../../../assets/Flag_of_Sweden.svg";
import UKFlag from "../../../assets/Flag_of_the_United_Kingdom.svg";
const TranslationContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Container = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) and (min-width: 300px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const Flag = styled.img`
  width: 30px;
  height: 20px;
  cursor: pointer;
  border-bottom: ${({ isActive }) => (isActive ? "1px solid white" : "none")};
`;

const Translation = () => {
  const [t, i18n] = useTranslation("translations");
  const [lang, setLang] = useState("en");
  const dispatch = useDispatch();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLang(language);
    dispatch(updateLanguage(language));
  };

  return (
    <Container>
      <TranslationContainer>
        <Flag
          src={UKFlag}
          alt="UK Flag"
          onClick={() => changeLanguage("en")}
          isActive={lang === "en"}
        />
        <Flag
          src={SwedenFlag}
          alt="Sweden Flag"
          onClick={() => changeLanguage("sv")}
          isActive={lang === "sv"}
        />
      </TranslationContainer>
    </Container>
  );
};

export default Translation;
