import { ApiStatus } from "../../components/Constants/Constants";
import * as actionType from "./actionTypes";
import axios from "axios";

//Thunks

export const updateSendEmailApiStatus = (status) => {
  return {
    type: actionType.API_SEND_ASK_FOR_PRICE_EMAIL,
    payload: status,
  };
};

//action creators
export const sendAskForPriceEmail = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateSendEmailApiStatus(ApiStatus.Started));
      axios
        .post(
          "https://us-central1-mattgallery-71669.cloudfunctions.net/sendgrid",
          {
            title: data.title,
            email: data.email,
            itemNumber: data.itemNumber,
            message: data.message,
            tel: data.tel,
            subject: "New user has requested a price",
          }
        )
        .then((response) =>
          dispatch(updateSendEmailApiStatus(ApiStatus.Completed))
        )
        .catch((error) => {
          dispatch(updateSendEmailApiStatus(ApiStatus.Failed));
          console.log(error.response);
        });
    } catch (error) {
      dispatch(updateSendEmailApiStatus(ApiStatus.Failed));
    }
  };
};

export const makeIdleSendAskForPriceEmail = () => {
  return async (dispatch) => {
    dispatch(updateSendEmailApiStatus(ApiStatus.Idle));
  };
};
