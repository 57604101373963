import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Colors, Sizes } from "../../Constants/Constants";
import Translation from "./Translation";
import { Link, NavLink } from "react-router-dom";
import BurgerMenu from "./BurgerMenu";
import useIsMobile from "../../../hooks/useWindowSize";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: ${Sizes.XXXLarge};
  background-color: ${Colors.White};
  border-bottom: 0.5px solid ${Colors.Gray};
  @media (max-width: 768px) and (min-width: 300px) {
    height: ${Sizes.XLarge};
    margin-top: ${Sizes.XXSmall};
  }
`;
const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const HeaderText = styled.h1`
  margin: 0;
  font-size: ${Sizes.Large};
  color: ${Colors.Black};
  text-transform: uppercase;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: ${Sizes.Small};
  }
`;
const MenuText = styled.span`
  font-size: ${Sizes.XSmall};
  margin: ${Sizes.XXSmall};
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  display: inline-block;

  &:hover::after {
    width: 100%;
  }
  &:after{
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: ${Colors.LightBlue};
    transition: 300ms;
  }
  }
`;

const PageHeader = () => {
  const { t } = useTranslation("translations");
  const isMobile = useIsMobile();
  return (
    <HeaderContainer>
      <Link to="/" style={{ textDecoration: "none" }}>
        <HeaderText>mattgallery</HeaderText>
      </Link>
      {isMobile ? (
        <BurgerMenu />
      ) : (
        <>
          <Translation />
          <MenuContainer>
            <NavLink
              to="/svenska-mattor"
              style={({ isActive }) =>
                isActive
                  ? { color: `${Colors.LightBlue}` }
                  : { color: `${Colors.Black}` }
              }
            >
              <MenuText>{t("Header.swedishCarpets")}</MenuText>
            </NavLink>
            <NavLink
              to="/persiska-mattor"
              style={({ isActive }) =>
                isActive
                  ? { color: `${Colors.LightBlue}` }
                  : { color: `${Colors.Black}` }
              }
            >
              <MenuText>{t("Header.persianCarpets")}</MenuText>
            </NavLink>
            <NavLink
              to="/moderna-mattor"
              style={({ isActive }) =>
                isActive
                  ? { color: `${Colors.LightBlue}` }
                  : { color: `${Colors.Black}` }
              }
            >
              <MenuText>{t("Header.modernCarpets")}</MenuText>
            </NavLink>
            <NavLink
              to="/om-oss"
              style={({ isActive }) =>
                isActive
                  ? { color: `${Colors.LightBlue}` }
                  : { color: `${Colors.Black}` }
              }
            >
              <MenuText>{t("Header.aboutUs")}</MenuText>
            </NavLink>
            <NavLink
              to="/kontakta-oss"
              style={({ isActive }) =>
                isActive
                  ? { color: `${Colors.LightBlue}` }
                  : { color: `${Colors.Black}` }
              }
            >
              <MenuText>{t("Header.contactUs")}</MenuText>
            </NavLink>
          </MenuContainer>
        </>
      )}
    </HeaderContainer>
  );
};

export default PageHeader;
