import styled from "styled-components";
import { Sizes } from "../../components/Constants/Constants";
import Button from "../../components/shared/Button/Button";

const CardContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};

  justify-content: center;
  align-items: center;
`;
const ButtonContainer = styled.div`
  margin-top: ${Sizes.Medium};
`;
const ImageContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) and (min-width: 300px) {
    width: 50%;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 30rem;
  object-fit: cover;
`;
const Title = styled.h2`
  font-weight: bold;
  font-size: ${Sizes.Large};
  @media (max-width: 768px) and (min-width: 300px) {
    margin: ${Sizes.XXXSmall};
    font-size: ${Sizes.XSmall};
  }
`;

const Text = styled.p`
  font-size: ${Sizes.XSmall};
  margin: 0 ${Sizes.Small};
  letter-spacing: 0.5px;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: ${Sizes.XXSmall};
  }
`;
const TextContainer = styled.div`
  @media (max-width: 768px) and (min-width: 300px) {
    width: 50%;
  }
`;

const CarpetCard = (props) => {
  return (
    <CardContainer reverse={props.reverse}>
      <ImageContainer>
        <Image src={props.imageSrc}></Image>
      </ImageContainer>
      <TextContainer>
        <Title>{props.title}</Title>
        <Text>{props.children}</Text>
        <ButtonContainer>
          <Button
            primary
            onClick={props.onButtonClick}
            title={props.buttonTitle}
          ></Button>
        </ButtonContainer>
      </TextContainer>
    </CardContainer>
  );
};

export default CarpetCard;
